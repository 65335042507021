import React from "react";
import { useController } from "react-hook-form";
import "./index.scss";
import CheckboxFieldProps from "./types";

function SelectField({
  name,
  label,
  control,
  disabled,
  customHandleChange,
  error,
  className = "",
}: CheckboxFieldProps) {
  const { field } = useController({ name, control });
  const activeError = !!error[field.name];

  return (
    <div className={`input-container ${className}`}>
      <div className="checkbox-container">
        <input
          type="checkbox"
          data-active={activeError}
          id={name}
          name={field.name}
          value={field.value}
          checked={field.value}
          disabled={disabled}
          onChange={(event) => {
            field.onChange(event);
            if (!!customHandleChange) customHandleChange(event.target.checked);
          }}
          onBlur={field.onBlur}
        />
        {label && (
          <label data-active={activeError} htmlFor={name} className="label">
            <span>{label}</span>
          </label>
        )}
      </div>
      {activeError && <p className="error">{error[field.name]?.message}</p>}
    </div>
  );
}

export default SelectField;
