import React from "react";
import "./index.scss";
import { ReactComponent as Logo } from "../../statics/icons/logo.svg";
import { ReactComponent as Calendar } from "../../statics/icons/calendar.svg";
import { ReactComponent as Clock } from "../../statics/icons/clock.svg";
import logoSp from "../../statics/imgs/logo_cidadeSP.png";
import sus from "../../statics/imgs/susu.png";
import sm from "../../statics/imgs/santa-marcelina-logo.png";
import { customWindow } from "../../configs/api";
import { BACK_IPS } from "../../configs";
function Footer() {
  const [dateState, setDateState] = React.useState<Date>(new Date());

  React.useEffect(() => {
    setInterval(() => setDateState(new Date()), 30000);
  }, []);

  return (
    <div className="footer-container">
      <div className="footer">
        <div
          className="footer-logo"
          style={{
            alignItems: "center",
          }}
        >
          <img src={sm} width={150} height={50} alt="logo" />
          <img src={sus} width={100} height={50} alt="logo" />
          <img src={logoSp} width={130} height={50} alt="logo" />
          <Logo
            style={{
              marginLeft: "50px",
            }}
          />
          <p
            style={{
              fontSize: 18,
              fontWeight: "bold",
              color: "#5a46dd",
            }}
          >
            {JSON.parse(localStorage.getItem("data") || "{}")?.company?.name}
          </p>
        </div>
        <div className="footer-informations">
          <div className="footer-icon">
            <Calendar width="36" height="36" />
            <p>
              {dateState.toLocaleDateString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
            </p>
          </div>
          <div className="footer-icon">
            <Clock width="36" height="36" />
            <p>
              {dateState.toLocaleString("pt-BR", {
                hour: "numeric",
                minute: "numeric",
              })}
            </p>
          </div>
        </div>
      </div>
      <div className="powered">
        <p
          style={{
            color: "#5a46dd",
            fontWeight: 300,
          }}
        >
          @Tsaude Chamador - Versão {customWindow?.TCALLER_VERSION} Ambiente (
          {customWindow?.API_URL
            ? BACK_IPS[customWindow.API_URL || "default"]
            : "DEV"} - @Powered By Tascom Tecnologia
          )
        </p>
      </div>
    </div>
  );
}

export default Footer;
