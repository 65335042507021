import React from "react";
import { useController } from "react-hook-form";
import "./index.scss";
import SelectFieldProps from "./types";

function SelectField({
  name,
  label,
  control,
  required,
  disabled,
  options,
  defaultValue,
  customHandleChange,
  error,
  className = "",
}: SelectFieldProps) {
  const { field } = useController({ name, control });
  const activeError = !!error[field.name];

  return (
    <div className={`input-container ${className}`}>
      {label && (
        <label data-active={activeError} htmlFor={name} className="label">
          <span>
            {required && <span>*</span>}
            {label}:
          </span>
        </label>
      )}
      <select
        data-active={activeError}
        id={name}
        name={field.name}
        value={field.value}
        disabled={disabled}
        defaultValue={defaultValue}
        onChange={(event) => {
          field.onChange(event);
          if (!!customHandleChange) customHandleChange(event.target.value);
        }}
        onBlur={field.onBlur}
      >
        {!!defaultValue && <option value={defaultValue}>{defaultValue}</option>}
        {options?.map((option, index) => (
          <option key={`${option}-${index}`} value={option}>
            {option}
          </option>
        ))}
      </select>
      {activeError && <p className="error">{error[field.name]?.message}</p>}
    </div>
  );
}

export default SelectField;
