function urlQuery<T extends {}>(params: T) {
  const paramsKeys = Object.keys(params) as unknown as Array<keyof T>;

  paramsKeys.forEach((value) => {
    if (params[value] === undefined || params[value] === null) {
      delete params[value];
    }
  });

  const paramsEntries = Object.entries(params) as unknown as Array<
    Array<keyof T | any>
  >;

  const querystring = paramsEntries
    .map((pair) => pair.map(encodeURIComponent).join("="))
    .join("&");
  return new URLSearchParams(querystring);
}

export default urlQuery;
