import { limitName } from "../../helpers";
import Patient from "../../models/Patient";
import "./index.scss";
interface NextPatientProps {
  patient: Patient;
  shouldShowPatientName?: boolean;
  size?: "lg" | "sm";
  customFontSize?: number;
}
function NextPatient({
  patient,
  size = "lg",
  customFontSize,
  shouldShowPatientName,
}: NextPatientProps) {
  return (
    <>
      <div
        className={`next-patient ${size}`}
        style={{
          fontSize: customFontSize,
        }}
      >
        <p
          style={{
            color: "#ef4444",
          }}
        >
          {patient && patient.token}
        </p>
        <p className="base">
          {shouldShowPatientName
            ? limitName(patient.name, 2, "Não Informado")
            : "****"}{" "}
        </p>
        <p className="base" style={{}}>
          {limitName(patient.station_name, 2, "Não Informado")}{" "}
        </p>
      </div>
    </>
  );
}

export default NextPatient;
