import React from "react";
import "./index.scss";
import Patient from "../../models/Patient";
import { limitName } from "../../helpers";

interface CurrentPatientProps {
  patient: Patient;
  caller?: string;
  shouldShowPatientName: boolean;
  mode?: any;
}

function CurrentPatient(props: CurrentPatientProps) {
  console.log(props);
  return (
    <>
      {props.patient.id ? (
        <div className={`patient-container ${!props.mode && "removeBorder"}`}>
          <h2
            id="name"
            style={{
              alignSelf: "center",
              display: "flex",
              gap: "10px",
              fontSize: "3em",
            }}
            className="loadingAnimation"
          >
            {props.shouldShowPatientName
              ? limitName(props.patient.name, 2)
              : "****"}
            {/* <span>
              {!!props.patient.pregnant && (
                <Pregnant className="light-icon" height="1.2em" width="1.2em" />
              )}
              {!!props.patient.disabled && (
                <Deficient
                  className="light-icon"
                  height="1.2em"
                  width="1.2em"
                />
              )}
              {!!props.patient.age && props.patient.age >= 60 && (
                <Elderly className="light-icon" height="1.2em" width="1.2em" />
              )}
            </span> */}
          </h2>
          <div
            className={`patient-information-container ${
              !props.mode && "removeBorder"
            }`}
          >
            <div className="patient-item">
              <p id="sector" className="base">
                Local :{" "}
                <span
                  className="primary"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {props?.patient.station_name}
                </span>
              </p>
            </div>
            <div className="patient-item">
              <p id="sector" className="base">
                Serviço :{" "}
                <span
                  className="primary"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {props.patient.service.hide_on_caller
                    ? "******"
                    : props?.patient.service.describe}
                </span>
              </p>
              <p className="base">{props.patient.time}</p>
            </div>
          </div>
        </div>
      ) : (
        <h2
          className="title primary loadingAnimation"
          style={{ marginTop: "20p" }}
        >
          Aguardando Chamada
        </h2>
      )}
    </>
  );
}

export default CurrentPatient;
