import React from "react";
import "./index.scss";
import ButtonProps from "./types";

const Button = ({
  type = "button",
  disabled,
  className = "",
  children,
  onClick,
}: ButtonProps) => {
  return (
    <button
      type={type}
      className={`button ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
