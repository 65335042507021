import React, { useState } from "react";
import { ReactComponent as TscmLogoBack } from "./statics/icons/background.svg";
import { ReactComponent as Logo } from "./statics/icons/tscmlogo.svg";
import { ReactComponent as LogoT } from "./statics/icons/logotsaude.svg";

import Background from "./statics/imgs/bgTexture.png";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ILogin } from "./contexts/AuthContext";
import { Button } from "./components/Buttons";
import { SelectField, TextField } from "./components/Fields";
import { useMutation } from "@tanstack/react-query";
import instance, { customWindow } from "./configs/api";
import { BACK_IPS } from "./configs";
const required = "É Requerido";
const validations = Yup.object().shape({
  passLogin: Yup.string().required(required),
  userLogin: Yup.string().required(required),
  company_id: Yup.mixed().required(required).nullable(),
});
function SignIn() {
  const [validUser, setValidUser] = useState<any>([]);
  const [companies, setCompanies] = useState<any>([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ILogin>({
    defaultValues: {
      microservice: true,
      passLogin: "",
      userLogin: "",
      company_id: "",
    },
    resolver: yupResolver(validations),
  });
  const onSubmit = (values: ILogin) => {
    if (!validUser.length) {
      verifyLoginMutation.mutate(values, {
        onSuccess: (data) => {
          const companiesNames = data.companies.map(
            (company: any) => company.name
          );
          setCompanies(data.companies);
          setValidUser(companiesNames);
        },
        onError: (err: any) => {
          alert(err.response.data.message);
        },
      });
    } else {
      values.company_id = companies.find(
        (company: any) => company.name === values.company_id
      )?.id;

      values.passLogin = values.passLogin.trim();
      values.userLogin = values.userLogin.trim();

      loginMutation.mutate(values, {
        onSuccess: (data: any) => {
          localStorage.setItem("token", data.token);
          localStorage.setItem("data", JSON.stringify(data));
          instance.defaults.headers["x-access-token"] = data.token;
          // eslint-disable-next-line no-restricted-globals
          location.reload();
        },
        onError: (err: any) => {
          alert(err.response.data.error);
        },
      });
    }
  };

  const verifyLoginMutation = useMutation((data: ILogin) => {
    return instance.post("/login/verify", data).then((res) => res.data);
  });

  const loginMutation = useMutation((data: ILogin) => {
    return instance.post("/login", data).then((res) => res.data);
  });

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          padding: 40,
          height: "100vh",
          backgroundImage: `url(${Background})`,
          position: "relative",
          display: "flex",
        }}
      >
        <Logo style={{ position: "absolute", top: 40, left: 40 }} />
        <div
          style={{
            height: "max-content",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
              textAlign: "center",
            }}
          >
            <h1
              style={{
                color: "black",
                fontWeight: "normal",
                margin: "0px auto",
                marginTop: 120,
                textAlign: "center",
                maxHeight: "max-content",
              }}
            >
              Bem vindo ao{" "}
              <span>
                <LogoT />
              </span>
            </h1>
            <p
              style={{
                color: "black",
                fontWeight: 200,
              }}
            >
              Medicina Preventiva | Chamador
            </p>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                textAlign: "left",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 20,
              }}
            >
              {!validUser?.length ? (
                <>
                  <TextField
                    name="userLogin"
                    control={control}
                    label="Login"
                    error={errors}
                  />
                  <TextField
                    name="passLogin"
                    control={control}
                    label="Senha"
                    error={errors}
                    type="password"
                  />
                  <Button type="submit">Avançar</Button>
                </>
              ) : (
                <>
                  <SelectField
                    name="company_id"
                    control={control}
                    error={errors}
                    options={["", ...validUser]}
                    label="Selecione a empresa"
                  />
                  <Button type="submit">Entrar</Button>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      setCompanies([]);
                      setValidUser([]);
                    }}
                  >
                    Voltar
                  </Button>
                </>
              )}
            </form>
            <div>
              <p
                style={{
                  color: "#5a46dd",
                  fontWeight: 300,
                }}
              >
                @Tsaude Chamador - Versão {customWindow?.TCALLER_VERSION}{" "}
                Ambiente (
                {customWindow?.API_URL
                  ? BACK_IPS[customWindow.API_URL || "default"]
                  : "DEV"}
                )
              </p>
            </div>
          </div>
        </div>
        <div></div>
      </div>

      <div
        style={{
          background: "#5a46dd",
          height: "100vh",
        }}
      >
        <TscmLogoBack
          style={{
            position: "absolute",
            bottom: 0,
          }}
        />
      </div>
    </div>
  );
}

export default SignIn;
