import React, { useEffect, useRef } from "react";
import "./index.scss";
import { ModalProps } from "./types";
import { ReactComponent as Close } from "../../statics/icons/close.svg";

const Modal = ({ open, handleClose, children }: ModalProps) => {
  const { current: body } = useRef(document.body);

  useEffect(() => {
    if (open) {
      body.style.overflowY = "hidden";
    } else {
      body.style.overflowY = "initial";
    }
  }, [body, open]);

  if (!open) return null;

  return (
    <div className="modal-container">
      <div className="modal">
        <Close className="close-icon" onClick={() => handleClose()} />
        {children}
      </div>
    </div>
  );
};

export default Modal;
