import { AxiosError } from "axios";
import { useCallback } from "react";
import instance from "../configs/api";
import { urlQuery } from "../helpers/";
import { Caller } from "../models";

const path = "/callers";

function useCaller() {
  const getCallers = useCallback(async (filters): Promise<Caller[]> => {
    try {
      const response = await instance.get(path, {
        params: urlQuery(filters),
      });
      return response.data;
    } catch (error) {
      const err = error as AxiosError<any>;
      throw new Error(err?.response?.data.message);
    }
  }, []);

  return {
    getCallers,
  };
}
export default useCaller;
