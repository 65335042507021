import React from "react";
import { createContext, ReactElement, useState } from "react";
import instance from "../configs/api";
import SignIn from "../SignIn";

export interface ILogin {
  passLogin: string;
  userLogin: string;
  microservice: boolean;
  token?: string;
  company_id?: string;
}
interface IAuthProvider {
  children: ReactElement;
}
interface IAuthContext {
  login: (data: ILogin) => void;
}
const AuthContext = createContext<IAuthContext>({} as IAuthContext);

const AuthProvider = ({ children }: IAuthProvider) => {
  const [user, setUser] = useState<boolean>(false);
  const login = (data: ILogin) => {};

  React.useEffect(() => {
    
    const token = localStorage.getItem("token");
    if (token) {
      instance.defaults.headers["x-access-token"] = token;
      setUser(true)
    }

  }, []);

  return (
    <AuthContext.Provider value={{ login }}>
      {!user ? <SignIn /> : children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
