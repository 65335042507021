import React from "react";
import { useController } from "react-hook-form";
import "./index.scss";
import TextFieldProps from "./types";

function SelectField({
  type = "text",
  name,
  label,
  control,
  required,
  disabled,
  customHandleChange,
  error,
  className = "",
}: TextFieldProps) {
  const { field } = useController({ name, control });
  const activeError = !!error[field.name];

  return (
    <div className={`input-container ${className}`}>
      {label && (
        <label data-active={activeError} htmlFor={name} className="label">
          <span>
            {required && <span>*</span>}
            {label}:
          </span>
        </label>
      )}
      <input
        type={type}
        data-active={activeError}
        id={name}
        name={field.name}
        value={field.value}
        disabled={disabled}
        onChange={(event) => {
          field.onChange(event);
          if (!!customHandleChange) customHandleChange(event.target.value);
        }}
        onBlur={field.onBlur}
      />
      {activeError && <p className="error">{error[field.name]?.message}</p>}
    </div>
  );
}

export default SelectField;
