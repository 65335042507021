import React, { useEffect, useRef, useState } from "react";
import { CurrentPatient, Footer, NextPatient } from "./components";
import { socket } from "./configs";
import Patient from "./models/Patient";
import "./statics/css/app.scss";
import { useTransition, animated, config } from "react-spring";
import { Modal } from "./components";
import {
  CheckboxField,
  RangeField,
  SelectField,
  TextField,
} from "./components/Fields";
import { Button } from "./components/Buttons";
import { useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { useCaller } from "./services";
import { useLocalStorage } from "./hooks";
import { limitName } from "./helpers";
import { CallerSettings } from "./models";
import { ReactComponent as ElderlyRounded } from "./statics/icons/elderly.svg";
import { ReactComponent as PregnantWomanRounded } from "./statics/icons/pregnant.svg";
import { ReactComponent as AssistWalkerRounded } from "./statics/icons/se.svg";
import { ReactComponent as EscalatorWarningRounded } from "./statics/icons/child.svg";
import { ReactComponent as AccessibleRounded } from "./statics/icons/deficient.svg";
import { ReactComponent as Extension } from "./statics/icons/autist.svg";
type FormValues = {
  caller_name: string;
  voice: string;
  volume: string;
  pitch: string;
  rate: string;
  test: string;
  queue_quantity: number;
  mode: boolean;
  sayPatientName: boolean;
  showPatientName: boolean;
};

const priorities: any = {
  idoso: (
    <ElderlyRounded
      style={{
        fill: "#ef4444",
      }}
      className="light-icon"
      height="1em"
      width="1em"
    />
  ),
  gestante: (
    <PregnantWomanRounded
      style={{
        fill: "#ef4444",
      }}
      className="light-icon"
      height="1em"
      width="1em"
    />
  ),
  "super idoso": (
    <AssistWalkerRounded
      style={{
        fill: "#ef4444",
      }}
      className="light-icon"
      height="1em"
      width="1em"
    />
  ),
  "criança de colo": (
    <EscalatorWarningRounded
      style={{
        fill: "#ef4444",
      }}
      className="light-icon"
      height="1em"
      width="1em"
    />
  ),
  deficiente: (
    <AccessibleRounded
      style={{
        fill: "#ef4444",
      }}
      className="light-icon"
      height="1em"
      width="1em"
    />
  ),
  autista: (
    <Extension
      style={{
        fill: "#ef4444",
      }}
      height="1em"
      width="1em"
    />
  ),
};
function App() {
  const execute = useRef(false);
  const count = useRef(1);
  const [voiceOptions, setVoiceOptions] = useState<string[]>([]);
  const [fontSize, setFontSize] = React.useState<number>(
    Number(localStorage.getItem("fontSize")) || 18
  );
  const [callerSettings, setCallerSettings] = useLocalStorage<CallerSettings>(
    "caller-settings",
    {
      caller_name: "",
      voice: "",
      volume: "1",
      pitch: "1",
      rate: "1",
      mode: true,
      queue_quantity: 5,
      sayPatientName: true,
      showPatientName: true,
    }
  );
  const [openSelection, setOpenSelection] = useState(false);
  const {
    control,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      caller_name: callerSettings.caller_name,
      voice: callerSettings.voice,
      volume: callerSettings.volume,
      pitch: callerSettings.pitch,
      rate: callerSettings.rate,
      test: "Valor de Teste, 1 2 3, Paciente Celton, por favor, dirija-se, Sala 01",
      queue_quantity: callerSettings.queue_quantity || 5,
      mode: callerSettings.mode,
      sayPatientName: callerSettings.sayPatientName,
      showPatientName: callerSettings.showPatientName,
    },
  });

  const textTotest = watch("test");

  const { getCallers } = useCaller();

  const callersQuery = useQuery(
    ["callers"],
    () => getCallers({ active: "true" }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      initialData: [],
      onSuccess: (data) => {
        if (!callerSettings.caller_name) {
          setCallerSettings((value: CallerSettings): any => {
            return { ...value, caller_name: data[0].name } as any;
          });
        }
      },
    }
  );
  const callersOptions = callersQuery.data.map(({ name }) => name);

  const togleSelection = () => {
    setOpenSelection((state) => !state);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    function openModalOnKeyUp({ code }: KeyboardEvent) {
      if (code === "KeyC") {
        ++count.current;
        if (count.current === 5) {
          togleSelection();
        }
        timeout = setTimeout(() => (count.current = 1), 1000);
      }
    }

    window.addEventListener("keyup", openModalOnKeyUp);
    return () => {
      if (timeout) clearTimeout(timeout);
      window.removeEventListener("keyup", openModalOnKeyUp);
    };
  }, [count]);

  socket.connect();
  const [current, setCurrent] = useState<any>({} as any);
  const [patientsHistory, setPatientsHistory] = useState<Patient[]>([]);
  const historyRef = useRef<Function>();

  const transitions = useTransition(patientsHistory && patientsHistory, {
    from: { opacity: 0, y: -10 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 0 },
    trail: 100,
    delay: 200,
    config: config.gentle,
  });

  historyRef.current = () => {
    if (current?.id) {
      patientsHistory.some((item, index) => {
        if (item.id === current?.id) {
          return true;
        }
        return false;
      });

      if (patientsHistory.length === callerSettings.queue_quantity) {
        patientsHistory.pop();
        patientsHistory.unshift(current);
        setPatientsHistory([...patientsHistory]);
      } else {
        setPatientsHistory((patientsHistory) => [current, ...patientsHistory]);
      }
    }
  };

  useEffect(() => {
    if (callerSettings) {
      socket.on(callerSettings.caller_name, (data: Patient) => {
        data.time = new Date().toLocaleString("pt-BR", {
          hour: "2-digit",
          minute: "2-digit",
        });
        setCurrent(data);
      });
    }
  }, [callerSettings]);
  useEffect(() => {
    socket.on("reset_caller", () => {
      window.location.reload();
    });

    socket.on("reset_tokens", () => {
      setCurrent({} as Patient);
      setPatientsHistory([]);
      const synth = window?.speechSynthesis;
      synth?.cancel();
    });
  }, []);
  useEffect(() => {
    const language = "pt-BR";
    const synth = window.speechSynthesis;

    synth.onvoiceschanged = () => {
      const voices = synth.getVoices();
      const voiceOptions: string[] = [];

      voices.forEach(({ name, lang }) => {
        if (lang === language) voiceOptions.push(name);
      });

      if (!callerSettings.voice) {
        setCallerSettings({
          ...callerSettings,
          voice: voiceOptions[0],
        });
      }

      setVoiceOptions(voiceOptions);
    };

    if (execute.current) {
      const name = limitName(current.name, 2);
      const sector = current?.service?.describe;
      const token = current?.token;

      // Senha, ${token}
      let tokenSliced = token?.split("")?.join(" ");
      const sayThis = new SpeechSynthesisUtterance(
        `${
          callerSettings.sayPatientName
            ? `Pasciente ${name}, Senha : ${tokenSliced}`
            : `Senha ${tokenSliced}`
        }, por favor, dirija-se, ${current.station_name}.`
      );

      if (historyRef?.current) historyRef?.current();

      const voices = synth.getVoices();

      if (voices.length) {
        const brVoices = voices.filter(({ lang }) => lang === language);
        const selectedVoice = brVoices.find(
          ({ name }) => name === callerSettings.voice
        );

        //Apple - 22
        //windows - 4
        sayThis.voice = selectedVoice ? selectedVoice : brVoices[0];
        sayThis.volume = Number(callerSettings.volume);
        // 0 <-> 2
        sayThis.pitch = Number(callerSettings.pitch);
        // 0.1 <-> 10
        sayThis.rate = Number(callerSettings.rate);

        synth.speak(sayThis);
      }
    } else {
      execute.current = true;
    }
  }, [current]);

  function handleTest() {
    const synth = window.speechSynthesis;
    synth.cancel();
    const sayThis = new SpeechSynthesisUtterance(textTotest);
    const voices = synth.getVoices();

    if (voices.length) {
      const brVoices = voices.filter(({ lang }) => lang === "pt-BR");
      const selectedVoice = brVoices.find(
        ({ name }) => name === callerSettings.voice
      );

      sayThis.voice = selectedVoice ? selectedVoice : brVoices[0];
      sayThis.volume = Number(callerSettings.volume);
      sayThis.pitch = Number(callerSettings.pitch);
      sayThis.rate = Number(callerSettings.rate);
      synth.speak(sayThis);
    }
  }
  return (
    <>
      <Modal open={openSelection} handleClose={togleSelection}>
        <div
          className="fields-container"
          style={{
            overflow: "auto",
          }}
        >
          <h3 className="selection-title col-span-full">
            Configurações do chamador
          </h3>
          <SelectField
            label="Selecionar chamador"
            name="caller_name"
            control={control}
            error={errors}
            defaultValue={""}
            options={callersOptions}
            customHandleChange={(value) => {
              if (value)
                if (callerSettings?.caller_name) {
                  socket.removeListener(callerSettings?.caller_name);
                }

              setCallerSettings({
                ...callerSettings,
                caller_name: value,
              });
            }}
          />
          <SelectField
            label="Selecionar voz"
            name="voice"
            control={control}
            error={errors}
            options={voiceOptions}
            customHandleChange={(value) => {
              window.speechSynthesis.cancel();
              if (value)
                setCallerSettings({
                  ...callerSettings,
                  voice: value,
                });
            }}
          />
          <RangeField
            label="Volume"
            name="volume"
            className="col-span-full"
            control={control}
            error={errors}
            min={0.1}
            max={1}
            step={0.1}
            customHandleChange={(value) => {
              if (value)
                setCallerSettings({
                  ...callerSettings,
                  volume: value,
                });
            }}
          />
          <RangeField
            label="Tom da voz"
            name="pitch"
            className="col-span-full"
            control={control}
            error={errors}
            min={0.1}
            max={2}
            step={0.1}
            customHandleChange={(value) => {
              if (value)
                setCallerSettings({
                  ...callerSettings,
                  pitch: value,
                });
            }}
          />
          <RangeField
            label="Velocidade"
            name="rate"
            className="col-span-full"
            control={control}
            error={errors}
            min={0.1}
            max={2}
            step={0.1}
            customHandleChange={(value) => {
              if (value)
                setCallerSettings({
                  ...callerSettings,
                  rate: value,
                });
            }}
          />
          <div className="col-span-full test-container">
            <TextField
              label="Texto para teste"
              className="col-span-2"
              name="test"
              control={control}
              error={errors}
            />
            <Button disabled={!textTotest} onClick={handleTest}>
              Testar voz
            </Button>
          </div>
          <div className="col-span-full test-container">
            <CheckboxField
              label="Pronunciar o nome do paciente"
              name="sayPatientName"
              control={control}
              error={errors}
              customHandleChange={(value) => {
                setCallerSettings({
                  ...callerSettings,
                  sayPatientName: Boolean(value),
                });
              }}
            />
            <CheckboxField
              label="Exibir o nome do Paciente"
              name="showPatientName"
              control={control}
              error={errors}
              customHandleChange={(value) => {
                setCallerSettings({
                  ...callerSettings,
                  showPatientName: Boolean(value),
                });
              }}
            />
          </div>
          <h3 className="selection-title col-span-full">
            Configurações da fila
          </h3>
          <TextField
            type="number"
            label="Pacientes no histórico"
            name="queue_quantity"
            control={control}
            error={errors}
            customHandleChange={(value) => {
              if (value) {
                setCallerSettings({
                  ...callerSettings,
                  queue_quantity: Number(value),
                });

                setPatientsHistory((patients) =>
                  patients?.slice(0, Number(value))
                );
              }
            }}
          />
          <CheckboxField
            label="Modo confortável"
            name="mode"
            control={control}
            error={errors}
            customHandleChange={(value) => {
              setCallerSettings({
                ...callerSettings,
                mode: Boolean(value),
              });
            }}
          />
          <Button
            onClick={() => {
              // eslint-disable-next-line no-restricted-globals
              const agree = confirm("Deseja realmente sair do chamador?");
              if (agree) {
                localStorage.removeItem("token");
                localStorage.removeItem("data");
                // eslint-disable-next-line no-restricted-globals
                location.reload();
              }
            }}
          >
            Sair do Chamador
          </Button>
        </div>
      </Modal>
      <main className={!callerSettings.mode ? "container" : ""}>
        <section
          className={`queue-container ${!callerSettings.mode ? "" : "center"}`}
        >
          <h1 className="title">Ficha / Paciente</h1>
          <h1
            className="title error"
            style={{
              marginTop: "25px",
              marginBottom: !current.token ? "25px" : "0px",
              fontSize: "3rem",
              fontWeight: "bold",
            }}
          >
            {current.token ? current.token : "00000"}{" "}
            {priorities[current?.priority?.describe.toLowerCase() as any]}
          </h1>
          <CurrentPatient
            shouldShowPatientName={callerSettings.showPatientName}
            patient={current}
            mode={callerSettings.mode}
            caller={callerSettings.caller_name}
          />
          {callerSettings.mode && (
            <>
              <h2 className="subtitle">Histórico de Chamadas</h2>
              <div className="labels">
                <span>Senha</span>
                <span>Paciente</span>
                <span>Local</span>
              </div>
              {patientsHistory.length !== 0 ? (
                transitions((style, item) => (
                  <animated.div style={style}>
                    <NextPatient
                      customFontSize={fontSize}
                      key={item.id}
                      patient={item}
                      shouldShowPatientName={callerSettings.showPatientName}
                    />
                  </animated.div>
                ))
              ) : (
                <h2
                  style={{
                    marginTop: "20px",
                    fontWeight: "normal",
                  }}
                  className="primary titleAnimation"
                >
                  Nenhum atendimento no momento.
                </h2>
              )}
            </>
          )}
        </section>
        {!callerSettings.mode && (
          <section
            className="queue-history"
            style={{
              position: "relative",
            }}
          >
            <h2>Histórico de Chamadas</h2>
            <div className="labels">
              <span>Senha</span>
              <span>Paciente</span>
              <span>Local</span>
            </div>
            {patientsHistory.length !== 0 ? (
              transitions((style, item) => (
                <animated.div style={{ ...style }}>
                  <NextPatient
                    customFontSize={fontSize}
                    key={item.id}
                    patient={item}
                    size="sm"
                    shouldShowPatientName={callerSettings.showPatientName}
                  />
                </animated.div>
              ))
            ) : (
              <p
                style={{
                  marginTop: "20px",
                  fontWeight: "normal",
                }}
                className="primary titleAnimation"
              >
                Nenhum atendimento no momento.
              </p>
            )}
            <div
              className="fullScreen"
              style={{
                display: "flex",
                maxWidth: "100px",
                alignItems: "center",
                alignSelf: "flex-end",
                position: "absolute",
                bottom: 150,
                right: 30,
              }}
            >
              <button
                style={{
                  padding: 8,
                  border: "none",
                  fontSize: 12,
                  borderRadius: 5,
                }}
                onClick={() => {
                  setFontSize((fontSize: number) => {
                    const sum = ++fontSize;
                    localStorage.setItem("fontSize", JSON.stringify(sum));
                    return sum;
                  });
                }}
              >
                +
              </button>
              <p
                style={{
                  color: "black",
                  fontWeight: "bold",
                  width: 20,
                }}
              >
                {fontSize}
              </p>
              <button
                style={{
                  padding: 8,
                  border: "none",
                  fontSize: 12,
                  borderRadius: 5,
                }}
                onClick={() => {
                  setFontSize((fontSize: number) => {
                    if (fontSize > 0) {
                      const sub = --fontSize;
                      localStorage.setItem("fontSize", JSON.stringify(sub));
                      return sub;
                    }
                    return fontSize;
                  });
                }}
              >
                -
              </button>
            </div>
          </section>
        )}
      </main>
      <Footer />
    </>
  );
}
export default App;
