import axios from "axios";

interface CustomWindow extends Window {
  API_URL?: string;
  INTERNAL_VERSION?: string;
  TCALLER_VERSION?: string;
}

export const customWindow: CustomWindow = window;

const instance = axios.create({
  baseURL: customWindow.API_URL || "http://34.151.235.109:5201/api",
  // baseURL: "http://34.151.235.109:5201/api",
});

instance.defaults.headers["internal-version"] =  customWindow.INTERNAL_VERSION || "null";
// instance.defaults.headers["internal-version"] =  1;

export default instance;
