import React, { useEffect, useRef } from "react";
import { useController } from "react-hook-form";
import "./index.scss";
import RangeFieldProps from "./types";

function RangeField({
  name,
  label,
  control,
  min,
  max,
  step,
  required,
  disabled,
  customHandleChange,
  error,
  className = "",
}: RangeFieldProps) {
  const { field } = useController({ name, control });
  const activeError = !!error[field.name];
  const rangeField = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!!rangeField.current) {
      const value = ((Number(field.value) - min) / (max - min)) * 100;
      rangeField.current.style.background = `linear-gradient(to right, #6A57EE 0%, #6A57EE ${value}%, #eeeeee ${value}%, #eeeeee 100%)`;
    }
  }, [field.value, max, min]);

  return (
    <div className={`input-container ${className}`}>
      {label && (
        <label data-active={activeError} htmlFor={name} className="label">
          <span>
            {required && <span>*</span>}
            {label}:
          </span>
        </label>
      )}
      <input
        type="range"
        id={field.name}
        name={field.name}
        value={field.value}
        min={min}
        max={max}
        step={step}
        data-active={activeError}
        disabled={disabled}
        ref={rangeField}
        onChange={(event) => {
          field.onChange(event);
          if (!!customHandleChange) customHandleChange(event.target.value);
        }}
        onBlur={field.onBlur}
      />
      {activeError && <p className="error">{error[field.name]?.message}</p>}
    </div>
  );
}

export default RangeField;
