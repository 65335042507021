import io from "socket.io-client";

const BACK_IPS: any = {
  "https://api.tsaude.app/api/": "PRD",
  "https://api-hsm-tsaude-test-328400548518.us-central1.run.app/api/": "TEST",
  "rc-hsm.api.tsaude.app/api/": "HML",
  "http://34.151.235.109:5101/api/": "HML2",
  default: "DEV",
};
interface CustomWindow extends Window {
  API_URL?: string;
  SOCKET_API_URL?: string;
  TCALLER_VERSION?: string;
}

const customWindow: CustomWindow = window;
const host = customWindow?.SOCKET_API_URL;

const socket = io(host || "", {});
export { socket, BACK_IPS };
